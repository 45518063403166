import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiService from 'services/api.service'

export const getPromotionList = createAsyncThunk(
    'getPromotionList',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getPromotionList(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getSentPromotionList = createAsyncThunk(
    'getSentPromotionList',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getSentPromotionList(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getUsedPromotionList = createAsyncThunk(
    'getUsedPromotionList',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getUsedPromotionList(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getPromotionInfo = createAsyncThunk(
    'getPromotionInfo',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getPromotionInfo(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const createPromotion = createAsyncThunk(
    'createPromotion',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.createPromotion(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const updatePromotion = createAsyncThunk(
    'updatePromotion',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.updatePromotion(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const togglePromotion = createAsyncThunk(
    'togglePromotion',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.togglePromotion(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const initialData = {
    promotionList: [],
    sentPromotionList: [],
    usedPromotionList: [],
    promotionInfo: {},

    listLoading: false,
    formLoading: false,
    toggleLoading: false,

    toggle: false,
    promotionSort: {
        activePage: 1,
        start: 0,
        length: 10,
        search: '',
        total: 0,
    },
    sentPromotionSort: {
        activePage: 1,
        start: 0,
        length: 10,
        search: '',
        total: 0,
        start_date: '',
        end_date: '',
    },
    usedPromotionSort: {
        activePage: 1,
        start: 0,
        length: 10,
        search: '',
        total: 0,
        start_date: '',
        end_date: '',
    },
}

const dataSlice = createSlice({
    name: 'promotion',
    initialState: initialData,
    reducers: {
        setToggle: (state, action) => {
            state.toggle = action.payload
        },
        setPromotionSort: (state, action) => {
            state.promotionSort = action.payload
        },
        setSentPromotionSort: (state, action) => {
            state.sentPromotionSort = action.payload
        },
        setUsedPromotionSort: (state, action) => {
            state.usedPromotionSort = action.payload
        },
    },
    extraReducers: {
        [getPromotionList.rejected]: (state) => {
            state.listLoading = false
        },
        [getPromotionList.fulfilled]: (state, action) => {
            state.listLoading = false
            state.promotionList = action.payload.data.aaData
            state.promotionSort.total = action.payload.data.iTotalDisplayRecords
        },
        [getPromotionList.pending]: (state) => {
            state.listLoading = true
        },
        [getSentPromotionList.rejected]: (state) => {
            state.listLoading = false
        },
        [getSentPromotionList.fulfilled]: (state, action) => {
            state.listLoading = false
            state.sentPromotionList = action.payload.data.aaData
            state.sentPromotionSort.total =
                action.payload.data.iTotalDisplayRecords
        },
        [getSentPromotionList.pending]: (state) => {
            state.listLoading = true
        },
        [getUsedPromotionList.rejected]: (state) => {
            state.listLoading = false
        },
        [getUsedPromotionList.fulfilled]: (state, action) => {
            state.listLoading = false
            state.usedPromotionList = action.payload.data.aaData
            state.usedPromotionSort.total =
                action.payload.data.iTotalDisplayRecords
        },
        [getUsedPromotionList.pending]: (state) => {
            state.listLoading = true
        },
        [getPromotionInfo.rejected]: (state) => {
            state.formLoading = false
        },
        [getPromotionInfo.fulfilled]: (state, action) => {
            state.formLoading = false
            state.promotionInfo = action.payload.data
        },
        [getPromotionInfo.pending]: (state) => {
            state.formLoading = true
        },
        [createPromotion.rejected]: (state) => {
            state.formLoading = false
        },
        [createPromotion.fulfilled]: (state) => {
            state.formLoading = false
        },
        [createPromotion.pending]: (state) => {
            state.formLoading = true
        },
        [updatePromotion.rejected]: (state) => {
            state.formLoading = false
        },
        [updatePromotion.fulfilled]: (state) => {
            state.formLoading = false
        },
        [updatePromotion.pending]: (state) => {
            state.formLoading = true
        },
        [togglePromotion.rejected]: (state) => {
            state.toggleLoading = false
        },
        [togglePromotion.fulfilled]: (state) => {
            state.toggleLoading = false
        },
        [togglePromotion.pending]: (state) => {
            state.toggleLoading = true
        },
    },
})

export const {
    setToggle,
    setPromotionSort,
    setSentPromotionSort,
    setUsedPromotionSort,
} = dataSlice.actions

export default dataSlice.reducer
