import React, { forwardRef, useEffect } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'

// Import Delta from Quill so we can return an empty Delta for images.
const Delta = Quill.import('delta')

// Define modules with a clipboard matcher that filters out images.
const modules = {
    clipboard: {
        matchers: [
            [
                'img',
                (node, delta) => {
                    // Returning an empty Delta prevents images from being pasted.
                    return new Delta()
                },
            ],
        ],
    },
}

const RichTextEditor = forwardRef((props, ref) => {
    const { disabled, maxLength } = props

    useEffect(() => {
        if (ref && ref.current && maxLength) {
            const quill = ref.current.getEditor()

            const handleTextChange = () => {
                if (quill.getLength() > maxLength + 1) {
                    quill.deleteText(maxLength, quill.getLength())
                }
            }
            quill.on('text-change', handleTextChange)
            return () => {
                quill.off('text-change', handleTextChange)
            }
        }
    }, [ref, maxLength])

    return (
        <div className={`rich-text-editor --${disabled ? 'disabled' : ''}`}>
            <ReactQuill ref={ref} modules={modules} {...props} />
        </div>
    )
})

export default RichTextEditor
