import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiService from '../../services/api.service'
import { useSelector } from 'react-redux'

export const getUserList = createAsyncThunk(
    'getUserList',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getUserList(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getCommissionUserList = createAsyncThunk(
    'getCommissionUserList',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getCommissionUserList(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const createUser = createAsyncThunk(
    'createUser',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.createUser(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getUserInfo = createAsyncThunk(
    'getUserInfo',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getUserInfo(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const updateUser = createAsyncThunk(
    'updateUser',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.updateUser(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const toggleUser = createAsyncThunk(
    'toggleUser',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.toggleUser(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const updatePassword = createAsyncThunk(
    'updatePassword',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.updatePassword(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getRoleList = createAsyncThunk(
    'getRoleList',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getRoleList(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const initialData = {
    userList: [],
    commissionUserList: [],
    roleList: [],

    formLoading: false,
    listLoading: true,
    updateLoading: false,
    createLoading: false,

    toggle: false,

    userSort: {
        activePage: 1,
        start: 0,
        length: 10,
        search: '',
        total: 0,
        type:
            localStorage.getItem('role') === 'admin'
                ? 'operation'
                : localStorage.getItem('role') === 'superadmin'
                ? 'admin'
                : 'user',
    },
}

const dataSlice = createSlice({
    name: 'user/data',
    initialState: initialData,
    reducers: {
        setUserSort: (state, action) => {
            state.userSort = action.payload
        },
        setListLoading: (state, action) => {
            state.listLoading = action.payload
        },
        setToggle: (state, action) => {
            state.toggle = action.payload
        },
        clearUserList: (state, action) => {
            state.userList = []
        },
    },
    extraReducers: {
        //list
        [getUserList.rejected]: (state) => {
            state.listLoading = false
        },
        [getUserList.fulfilled]: (state, action) => {
            state.listLoading = false
            state.userList = action.payload.data.aaData
            state.userSort.total = action.payload.data.iTotalDisplayRecords
        },
        [getUserList.pending]: (state) => {
            state.listLoading = true
        },

        [getCommissionUserList.rejected]: (state) => {
            state.listLoading = false
        },
        [getCommissionUserList.fulfilled]: (state, action) => {
            state.listLoading = false
            state.commissionUserList = action.payload.data
            // state.userSort.total = action.payload.data.iTotalDisplayRecords
        },
        [getCommissionUserList.pending]: (state) => {
            state.listLoading = true
        },

        [getRoleList.rejected]: (state) => {},
        [getRoleList.fulfilled]: (state, action) => {
            state.roleList = action.payload.data
        },
        [getRoleList.pending]: (state) => {},

        //info
        [getUserInfo.rejected]: (state) => {
            state.formLoading = false
        },
        [getUserInfo.fulfilled]: (state, action) => {
            state.formLoading = false
            state.userInfo = action.payload.data
        },
        [getUserInfo.pending]: (state) => {
            state.formLoading = true
        },

        //create
        [createUser.rejected]: (state) => {
            state.createLoading = false
        },
        [createUser.fulfilled]: (state, action) => {
            state.createLoading = false
        },
        [createUser.pending]: (state) => {
            state.createLoading = true
        },

        //update
        [updateUser.rejected]: (state) => {
            state.updateLoading = false
        },
        [updateUser.fulfilled]: (state, action) => {
            state.updateLoading = false
        },
        [updateUser.pending]: (state) => {
            state.updateLoading = true
        },

        [updatePassword.rejected]: (state) => {
            state.updateLoading = false
        },
        [updatePassword.fulfilled]: (state, action) => {
            state.updateLoading = false
        },
        [updatePassword.pending]: (state) => {
            state.updateLoading = true
        },

        //toggle
        [toggleUser.rejected]: (state) => {
            state.toggleLoading = false
        },
        [toggleUser.fulfilled]: (state, action) => {
            state.toggleLoading = false
        },
        [toggleUser.pending]: (state) => {
            state.toggleLoading = true
        },
    },
})

export const { setUserSort, setListLoading, setToggle, clearUserList } =
    dataSlice.actions

export default dataSlice.reducer
